.video-hero{
    position: relative;
    padding: 0;


    .hero-inner{
        position: absolute;
        top: 40%;
        width: 100%;
        z-index: 10;
    }
}



.inner-page-hero h2{
    background: green;
}



.video-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 550px;
    overflow: hidden;
    z-index: -1;
}
video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 768px) {
    .video-container {
        position: relative;
        height: 300px; 
    }
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
