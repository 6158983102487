.theme-btn {
  border-radius: 5px;
  border: 0;
}
.theme-btn.green {
  background-color: green;
}

.right-review .theme-btn {
  border-radius: 5px;
  background-color: green;
}

.how-it-works {
  position: relative;
  background-image: url(../../images/aantafel.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0;
}
.how-it-works:before {
  content: "";
  background-color: #131924;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.how-it-works .container {
  position: relative;
  z-index: 10;
}
.how-it-works h2 {
  font-size: 2.5rem;
}

.card {
  background: transparent;
  border: 0;
}
.card .card-header {
  background: transparent;
  padding: 0.75rem 1.25rem 2rem;
}
.card .card-header img {
  width: 100%;
  height: auto;
}
.card .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  background: transparent;
  border-top: 0;
}
.card .btn {
  display: inline-block;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 5px;
}
.card .btn.green {
  background-color: green;
}

.page-id-34653 #header {
  display: none;
}
.page-id-34653 .footer {
  display: none;
}

.dashboard-page {
  padding: 0 24px;
}

.dashboard {
  height: 100vh;
  display: flex;
  padding: 120px 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.dashboard .row-block {
  margin: 24px 0;
}

.order-list {
  display: flex;
  gap: 12px;
  flex-flow: row wrap;
  align-items: stretch;
}

.order-item {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 240px;
  height: 100%;
  box-shadow: 0px 6px 24px -6px rgba(0, 0, 0, 0.1);
}
.order-item.-yellow .inner {
  background: #ffefc5;
}
@keyframes blink {
  0% {
    background: #ffc5c5;
  }
  25% {
    background: #f58787;
  }
  75% {
    background: #f58787;
  }
  100% {
    background: #ffc5c5;
  }
}
.order-item.-red .inner {
  background: #ffc5c5;
}
.order-item .inner {
  padding: 12px 16px;
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 6px;
  height: 100%;
}
.order-item .inner .head {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: solid 1px #999999;
  padding-bottom: 10px;
}
.order-item .inner .head .type {
  width: 100%;
  font-weight: bold;
}
.order-item .inner .head .title {
  font-weight: normal;
  font-size: 0.8rem;
}
.order-item .inner .head .timer {
  font-size: 0.85em;
}
.order-item .inner .content {
  padding: 5px 0 0px;
}
.order-item .inner .phone {
  margin-top: 12px;
}
.order-item .inner .ctas {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 16px;
}
.order-item .inner .ctas a {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  padding: 3px 6px 3px 3px;
}
.order-item .inner .ctas a.button {
  color: blue;
  border: solid 1px blue;
  border-radius: 5px;
}
.order-item .inner .ctas a.button:hover {
  color: white;
  background: blue;
}
.order-item .inner .ctas a.button-secondary {
  color: green;
  border: solid 1px green;
  border-radius: 5px;
}
.order-item .inner .ctas a.button-secondary:hover {
  color: white;
  background: green;
}
.order-item .inner .ctas a .dashicons {
  transform: translate(0, 2px);
  transition: all 0s 0s ease;
}

.navbar, .sidebar-title, .theme-btn, .close-btn, #back-to-top, .theme-btn-dash:hover {
  background-color: #131924;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.video-hero {
  position: relative;
  padding: 0;
}
.video-hero .hero-inner {
  position: absolute;
  top: 40%;
  width: 100%;
  z-index: 10;
}

.inner-page-hero h2 {
  background: green;
}

.video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 550px;
  overflow: hidden;
  z-index: -1;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .video-container {
    position: relative;
    height: 300px;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}