.theme-btn{
    border-radius: 5px;
    border:0;

    &.green{
        background-color: green;
    }
}


.right-review{
    .theme-btn{
        border-radius: 5px;
        background-color: green;
    }
}

.how-it-works {
    position: relative;
    background-image: url(../../images/aantafel.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
    &:before{
        content: '';
        background-color: #131924;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.7;
    }
    .container{
        position: relative;
        z-index: 10;
    }
    h2{
        font-size: 2.5rem;;
    }
}


.card{
    background: transparent;
    border: 0;
    .card-header{
        background: transparent;
        padding: .75rem 1.25rem 2rem;
        img{
            width: 100%;
            height: auto;
        }
    }
    .card-footer{
        display: flex;
        justify-content: space-between;
        align-items: start;
        background: transparent;
        border-top:0;
    }
    .btn{
        display: inline-block;
        color: #fff;
        font-size: 0.9rem;
        border-radius: 5px;
        &.green{
            background-color: green;
        }
    }
}