.page-id-34653{
    #header{
        display:none;
    }
    .footer{
        display:none;
    }
}

.dashboard-page{
    padding:0 24px;
}
.dashboard{
    height:100vh;
    display:flex;
    padding:120px 0;
    flex-flow:column nowrap;
    justify-content:flex-start;
    .row-block{
        margin:24px 0;
    }
}

.order-list{
    display:flex;
    gap:12px;
    flex-flow:row wrap;
    align-items:stretch;
}
.order-item{
    padding:0;
    margin:0;
    list-style-type:none;
    width:240px;
    height:100%;
    box-shadow:0px 6px 24px -6px rgba(0,0,0,0.1);
    &.-yellow{
        .inner{
            background:#ffefc5;
        }
    }
    &.-red{
        @keyframes blink {
            0% {background:#ffc5c5;}
            25%{background:#f58787}
            75% {background:#f58787}
            100% {background:#ffc5c5}
        }
        .inner{
            background:#ffc5c5;
            // animation: blink 3s steps(5, start) infinite;
        }
        
    }
    .inner{
        padding:12px 16px;
        background:#fff;
        display:flex;
        flex-flow:column nowrap;
        border-radius:6px;
        height:100%;
        .head{
            display:flex;
            flex-flow:row wrap;
            justify-content:space-between;
            align-items:flex-end;
            border-bottom:solid 1px #999999;
            padding-bottom: 10px;
            .type{
                width:100%;
                font-weight: bold;
            }
            .title{
                font-weight:normal;
                font-size: 0.8rem;
            }
            .timer{
                font-size:0.85em;
            }   
        }
        .content{
            padding:5px 0 0px;
        }
        .phone{
            margin-top:12px;
        }
        .ctas{
            display:flex;
            flex-flow:row nowrap;
            justify-content:space-between;
            margin-top:16px;
            a{
                display:flex;
                flex-flow:row nowrap;
                gap:4px;
               
                padding:3px 6px 3px 3px;
                &.button{
                    color:blue;
                    border:solid 1px blue;
                    border-radius: 5px;
                    &:hover{
                        color:white;
                        background:blue;
                    }
                }
                &.button-secondary{
                    color:green;
                    border:solid 1px green;
                    border-radius: 5px;
                    &:hover{
                        color:white;
                        background:green;
                    }
                }
                .dashicons{
                    transform:translate(0, 2px);
                    transition:all 0s 0s ease;
                }
            }
        }
    }
}
